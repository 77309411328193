import { Typography } from "components/Typography/Typography"
import { useOrderContext } from "context/OrderContext"
import classes from './BasketInfo.module.scss'
import classNames from "classnames";
import { FaRegTrashAlt } from "react-icons/fa";
import { ItemInBasket } from "types/orderFormat";

export const BasketInfo = () => { 
    const { order, removeItem } = useOrderContext()
    
    if(!order || order.items.length < 1) {
        return null
    }

    const removeFromBasket = (item: ItemInBasket) => {
        removeItem(item)
    }

    return (
        <div className={classes.BasketInfo} data-testid='basket-summary-content'>
            <Typography format="h1" bold testID="basket-title">KOSZYK</Typography>
            <hr/>
            {order.items.map((item, index) => {
                const isLastElement = index === order.items.length - 1
                const key = `${item.id}-${item.name}--order-item`
                const testID = `${index}-order-item-basket-summary`
                return (
                    <div key={key} className={classNames(classes.BasketInfo__Item, isLastElement && classes.BasketInfo__LastItem)} data-testid={testID}>
                        <div>
                            <Typography format="h3" bold>{item.quanity}x {item.name}</Typography>
                            {item.radios.map((radio, index) => {
                                const radioKey = `${item.id}-${item.name}-${index}--radio`
                                return <Typography format="b" key={radioKey}>{radio}</Typography>
                            })}
                            {item.addOns.map((addOn, index) => {
                                 const addOnKey = `${item.id}-${item.name}-${index}--addOn`
                                return <Typography format="b" key={addOnKey}>{addOn}</Typography>
                            })}
                        </div>
                        <div className={classes.BasketInfo__Price}>
                            <Typography format="h3">{item.price.toFixed(2)} zł</Typography>
                            <FaRegTrashAlt className={classes.BasketInfo__DeleteItem} onClick={() => removeFromBasket(item)}/>
                        </div>
                    </div>
                )
            })}
             <hr/>
             <div className={classes.BasketInfo__FinalPrice}>
                <Typography format="h3" bold>Razem:</Typography>
                <Typography format="h3" bold testID="basket-summary-total-price">{order.fullPrice.toFixed(2)} zł</Typography>
            </div>
        </div>
    )
}
import { Typography } from "components/Typography/Typography";
import { BaseModal } from "../BaseModal/BaseModal";
import { BasketInfo } from "./BasketInfo/BasketInfo";
import classes from "./BasketModal.module.scss";
import { DeliveryTypeSelector } from "./SelectDelivery/DeliveryTypeSelector/DeliveryTypeSelector";
import { useEffect, useMemo, useState } from "react";
import {
  deliveryInitialValues,
  DeliveryType,
  DeliveryTypeForm,
  OrderFormValues,
  pickupInitialValues,
} from "./types";
import { PickupSelection } from "./SelectDelivery/PickupSelection/PickupSelection";
import { DeliverSelection } from "./SelectDelivery/DeliverSelection/DeliverSelection";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Checkbox } from "./Checkbox/Checkbox";
import { CommentBox } from "./CommentBox/CommentBox";
import {
  pickupValidationSchema,
  deliveryValidationSchema,
} from "./validationSchema";
import { OrderCloseBasketBar } from "./OrderCloseBasketBar/OrderCloseBasketBar";
import { TCsRow } from "./TCs/TCsRow";
import { useRequestPaymentAction } from "effects/useRequestPayment";
import { useGetSettings } from "hooks/useGetSettings";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { SharedFields } from "./SelectDelivery/SharedFields/SharedFields";
import { checkAddreesLocation } from "./utils";

interface BasketModalProps {
  onClose: () => void;
  setLoading: (isLoading: boolean) => void;
}

export const BasketModal = ({ onClose, setLoading }: BasketModalProps) => {
  const [selected, setSelected] = useState(DeliveryType.PICK_UP)
  const [isDeliveryAvaliable, setIsDeliveryAvaliable] = useState(true)
  const { fetchSettings } = useGetSettings()
  const { requestOrder } = useRequestPaymentAction()

  useEffect(() => {
     fetchSettings().then((response) => {
      setIsDeliveryAvaliable(response?.delivery ?? false)
     })
  }, [])

  const validationSchema = useMemo(() => {
    return selected === DeliveryType.PICK_UP
      ? pickupValidationSchema
      : deliveryValidationSchema;
  }, [selected]);

  const initialValues = useMemo(() => {
    return selected === DeliveryType.PICK_UP
      ? pickupInitialValues
      : deliveryInitialValues;
  }, [selected]);

  const submitOrder = async (values: OrderFormValues) => {
    setLoading(true);
    if(values.type === DeliveryType.DELIVERY) {
      const delValues = (values as DeliveryTypeForm).placeObject
      if(!checkAddreesLocation(delValues)) {
        setLoading(false)
        onClose()
        toast.error('Nie możemy przyjąć zamówienia, ponieważ podany adres jest dalej niż 6 km od naszej lokalizacji.')
        return
      }
      
    }
    await fetchSettings().then((response) => {
      if(!response?.delivery && !response?.collection) {
        setLoading(false)
        onClose()
        toast.error('Nie przyjmujemy teraz zamówień. Po więcej informacji prosimy odwiedzić nasz profil na Facebooku.')
        return
      }
  
      if(values.type === DeliveryType.DELIVERY && !response?.delivery) {
        setLoading(false)
        onClose()
        toast.error('Nie przyjmujemy teraz zamówień na dowóz. Po więcej informacji prosimy odwiedzić nasz profil na Facebooku.')
        return
      }
    })

    await requestOrder(values).then(() => setLoading(false))
  };

  return (
    <>
      <BaseModal onClose={onClose}>
        <div className={classes.BasketModal}>
          <BasketInfo />
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={false}
              onSubmit={(values, { setSubmitting, validateForm }) => {
                validateForm().then((errors) => {
                  onClose();
                  if (Object.keys(errors).length === 0) {
                    submitOrder(values);
                  } else {
                  }
                });
                setSubmitting(false);
              }}
            >
              <Form>
                <DeliveryTypeSelector
                  selected={selected}
                  setSelected={setSelected}
                  isDeliveryAvaliable={isDeliveryAvaliable}
                />
                <SharedFields/>
                {selected === DeliveryType.PICK_UP ? (
                  <PickupSelection />
                ) : (
                  <DeliverSelection />
                )}
                <div
                  className={classes.BasketModal__Checkbox}
                  data-testid="add-cutlery-checkbox-field"
                >
                  <Checkbox />
                  <Typography format="h3">Dodaj sztućce</Typography>
                </div>
                <CommentBox testID="basket-comment-box" />
                  <Field name="tcsCheck" component={TCsRow} />
                <ErrorMessage name="tcsCheck">
                  {(msg) => (
                    <Typography
                      format="b"
                      paragraphClassname={classes.BasketModal__ErrorMessage}
                    >
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
                <OrderCloseBasketBar onClose={onClose} />
              </Form>
            </Formik>
          </div>
        </div>
      </BaseModal>
    </>
  );
};
